import {
  useScroll,
  m,
  useSpring,
  useTransform,
  useVelocity,
  useMotionValue,
  wrap,
  useAnimationFrame,
  useWillChange,
} from 'framer-motion'
import {useRef} from 'react'

import {colors} from '~/utils/colors'
import {eas} from '~/utils/easings'

import Button from '../shared/button'
import {HeaderColorWrapper} from '../shared/header-color-wrapper'
import Image from '../shared/image'

const svgColor = '#FAD103'

export default function FeaturedProduct({data}: any) {
  const featuredProductRef = useRef<HTMLElement>(null!)

  const newRef = useRef<SVGSVGElement>(null)

  // const willChange = useWillChange()

  const {scrollY} = useScroll({
    target: featuredProductRef,
    offset: ['start end', 'end start'],
  })

  const baseRotate = useMotionValue(0)

  const scrollVelocity = useVelocity(scrollY)
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 100,
  })
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 8], {
    clamp: false,
  })
  const rotate = useTransform(baseRotate, (v) => `${wrap(-0, 360, v)}deg`)

  const directionFactor = useRef<number>(1)
  const baseVelocity = 10

  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000)

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get()

    baseRotate.set(baseRotate.get() + moveBy)
  })
  const viewPortSettings = {once: true}

  const springConfigImage = {
    duration: 1.2,
    ease: eas.outExpo,
  }

  return (
    <HeaderColorWrapper headerColor={colors.softorange}>
      <div className="bg-sand relative z-10 w-screen overflow-hidden" ref={featuredProductRef}>
        <div className="wrapper py-c-6 md:py-c-2 flex flex-col items-center">
          <m.div
            transition={{...springConfigImage, delay: 0.1}}
            initial={{opacity: 0, y: '10%'}}
            whileInView={{opacity: 1, y: 0}}
            viewport={viewPortSettings}
            className="flex flex-col items-center"
          >
            {data.title && (
              <h2 className="tracking-pn-3 pb-c-0.5 md:pb-c-0.1 overflow-hidden text-[4rem] leading-[90%] md:text-[7rem]">
                {data.title}
              </h2>
            )}
            {data.subtitle && (
              <em className="leading-1 tracking-pn-1 pb-c-1 md:pb-c-0.25 overflow-hidden text-[2.4rem] md:text-[3.2rem]">
                {data.subtitle}
              </em>
            )}
            {data.button && <Button data={data.button} className="z-20" />}
          </m.div>

          <div className="pointer-events-none relative flex items-center justify-center pt-0 md:mt-[-8rem] md:mb-[-8rem]">
            {data.image && (
              <m.div
                transition={{...springConfigImage, delay: 0.1}}
                initial={{rotate: -10, y: '20%', scale: 0.9}}
                whileInView={{rotate: 0, y: 0, scale: 1}}
                viewport={viewPortSettings}
                className="z-10"
              >
                <Image src={data.image} className=" w-[30rem] md:w-[70rem]" />
              </m.div>
            )}
            <m.div style={{rotate}} className="fill-ocher absolute w-[30rem] md:w-[60rem]">
              <svg
                viewBox="0 0 375 454"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex"
                ref={newRef}
              >
                <path
                  d="M235.102 375.029L239.938 373.809L231.399 339.957L227.047 341.055L233.905 368.244L207.381 346.015L203.673 346.951L212.212 380.802L216.564 379.704L210.032 353.805L235.102 375.029Z"
                  fill={svgColor}
                />
                <path
                  d="M173.289 350.812L193.512 351.181L193.293 363.204L177.613 362.918L177.541 366.852L193.221 367.138L193.021 378.108L173.296 377.748L173.224 381.738L197.602 382.182L198.239 347.276L173.362 346.823L173.289 350.812Z"
                  fill={svgColor}
                />
                <path
                  d="M138.732 373.685L143.008 375.05L159.036 349.801L159.248 349.869L157.42 379.65L161.855 381.066L163.971 345.094L158.692 343.409L143.142 367.705L142.931 367.638L144.491 338.876L139.159 337.175L120.044 367.72L124.32 369.085L139.96 343.596L140.171 343.664L138.732 373.685Z"
                  fill={svgColor}
                />
                <path
                  d="M94.1583 349.548L116.04 322.345L112.284 319.324L90.4017 346.527L94.1583 349.548Z"
                  fill={svgColor}
                />
                <path
                  d="M76.504 333.854L79.6862 337.695L106.568 315.419L103.704 311.963L82.1133 329.854L90.7635 296.346L88.3238 293.402L61.4418 315.677L64.3057 319.133L84.8726 302.091L76.504 333.854Z"
                  fill={svgColor}
                />
                <path
                  d="M41.7338 274.048L42.935 278.889L76.819 270.481L75.738 266.124L48.5232 272.877L70.8532 246.439L69.9323 242.728L36.0483 251.136L37.1294 255.493L63.0533 249.06L41.7338 274.048Z"
                  fill={svgColor}
                />
                <path
                  d="M66.3099 212.722L65.8656 232.943L53.8433 232.679L54.1878 217.001L50.2543 216.914L49.9098 232.593L38.9402 232.352L39.3736 212.629L35.3846 212.541L34.849 236.918L69.7522 237.685L70.2988 212.809L66.3099 212.722Z"
                  fill={svgColor}
                />
                <path
                  d="M43.6002 178.072L42.2198 182.343L67.4099 198.463L67.3418 198.674L37.5673 196.739L36.1357 201.168L72.0992 203.415L73.8035 198.142L49.5641 182.504L49.6323 182.293L78.3878 183.958L80.1091 178.632L49.6332 159.406L48.2528 163.677L73.6847 179.409L73.6165 179.62L43.6002 178.072Z"
                  fill={svgColor}
                />
                <path
                  d="M67.8793 133.687L95.0015 155.669L98.0371 151.923L70.915 129.941L67.8793 133.687Z"
                  fill={svgColor}
                />
                <path
                  d="M83.6473 116.09L79.795 119.258L101.969 146.224L105.436 143.373L87.6264 121.715L121.101 130.491L124.055 128.063L101.881 101.097L98.4144 103.948L115.379 124.579L83.6473 116.09Z"
                  fill={svgColor}
                />
                <path
                  d="M143.577 81.5181L138.727 82.6821L146.875 116.63L151.24 115.582L144.696 88.3163L170.962 110.849L174.68 109.956L166.532 76.0086L162.167 77.0562L168.401 103.029L143.577 81.5181Z"
                  fill={svgColor}
                />
                <path
                  d="M204.573 106.361L184.353 105.841L184.662 93.8197L200.34 94.2227L200.441 90.2895L184.764 89.8865L185.045 78.9179L204.767 79.4248L204.869 75.4362L180.495 74.8097L179.597 109.71L204.471 110.349L204.573 106.361Z"
                  fill={svgColor}
                />
                <path
                  d="M239.678 83.8469L235.417 82.4354L219.114 107.508L218.904 107.438L221.056 77.6782L216.637 76.2145L214.128 112.161L219.389 113.903L235.203 89.7783L235.413 89.848L233.539 118.591L238.852 120.351L258.3 90.0155L254.039 88.6041L238.122 113.921L237.912 113.851L239.678 83.8469Z"
                  fill={svgColor}
                />
                <path
                  d="M283.581 108.031L261.499 135.071L265.233 138.121L287.315 111.08L283.581 108.031Z"
                  fill={svgColor}
                />
                <path
                  d="M301.228 123.9L298.075 120.036L271.026 142.107L273.863 145.585L295.589 127.858L286.686 161.299L289.103 164.262L316.153 142.191L313.315 138.713L292.62 155.599L301.228 123.9Z"
                  fill={svgColor}
                />
                <path
                  d="M335.685 184.008L334.539 179.154L300.561 187.172L301.592 191.541L328.882 185.101L306.249 211.28L307.128 215.002L341.106 206.984L340.075 202.616L314.079 208.75L335.685 184.008Z"
                  fill={svgColor}
                />
                <path
                  d="M310.646 244.807L311.242 224.589L323.261 224.943L322.8 240.619L326.733 240.735L327.194 225.059L338.162 225.382L337.581 245.101L341.569 245.219L342.287 220.846L307.39 219.819L306.658 244.69L310.646 244.807Z"
                  fill={svgColor}
                />
                <path
                  d="M333.053 280.123L334.48 275.867L309.467 259.473L309.538 259.263L339.289 261.524L340.769 257.11L304.832 254.471L303.071 259.725L327.138 275.626L327.067 275.837L298.332 273.858L296.552 279.165L326.817 298.722L328.244 294.466L302.985 278.458L303.055 278.247L333.053 280.123Z"
                  fill={svgColor}
                />
                <path
                  d="M308.645 324.063L281.686 301.881L278.623 305.604L305.582 327.786L308.645 324.063Z"
                  fill={svgColor}
                />
                <path
                  d="M292.661 341.679L296.537 338.54L274.568 311.408L271.079 314.232L288.724 336.025L255.316 326.995L252.345 329.402L274.314 356.534L277.802 353.71L260.994 332.951L292.661 341.679Z"
                  fill={svgColor}
                />
              </svg>
            </m.div>
          </div>
        </div>
      </div>
    </HeaderColorWrapper>
  )
}
