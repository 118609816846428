import RichText from '../shared/rich-text'

export default function Text({data}: any) {
  return (
    <section
      style={{
        backgroundColor: data.backgroundColor,
        color: data.textColor,
      }}
    >
      <div className="wrapper">
        <div>
          {data.title && (
            <div className="tracking-pn-3 pt-c-9  pb-c-4 md:pt-c-3 md:pb-c-1 relative z-20 text-center text-[5.5rem] leading-[85%] md:text-[20rem] md:leading-[80%]">
              <RichText data={data.title} />
            </div>
          )}
          {data.text && (
            <div className="px-c-1 pb-c-4 md:w-c-10 md:pb-c-2 mx-auto text-base md:p-0">
              <RichText data={data.text} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
