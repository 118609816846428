import clsx from 'clsx'
import {useLayoutEffect, useRef} from 'react'
import {useIsMobile} from '~/hooks/is-mobile'
import {eas} from '~/utils/easings'

import Icon from '../shared/icon'
import Image from '../shared/image'
import RichText from '../shared/rich-text'

export default function highlight({data}: any) {
  const type = data.icon == 'sun' ? 1 : data.icon == 'octa' ? 2 : 3

  const isOne = type == 1
  const isTwo = type == 2
  const isThree = type == 3

  return (
    <section
      className="relative  w-screen overflow-hidden py-[10rem] md:h-[100rem] md:p-0"
      style={{
        backgroundColor: data.backgroundColor,
        color: data.textColor,
      }}
    >
      <div className="wrapper relative">
        <div
          className={clsx(
            isOne && 'md:top-[48rem]',
            isTwo && 'md:top-[45rem]',
            isThree && 'md:top-[48rem]',
            'tracking-pn-3 px-c-2 overflow-hidden text-[5rem] md:absolute md:left-1/2 md:z-20  md:-translate-y-1/2 md:-translate-x-1/2  md:transform md:whitespace-nowrap md:p-0 md:text-[20rem] md:leading-[1.1]'
          )}
        >
          <span className="block">{data.title}</span>
        </div>

        {data.text && (
          <div
            className={clsx(
              // 'text-black',
              isOne && 'md:w-c-6 md:left-c-15 md:top-[61rem]',
              isTwo && 'md:w-c-6 md:left-c-5 md:top-[58rem]',
              isThree && 'md:w-c-6 md:left-c-15 md:top-[61rem]',
              'px-c-2 pb-[4rem] text-base md:absolute md:p-0'
            )}
          >
            <RichText data={data.text} />
          </div>
        )}

        {data.images && (
          <div className="pt-c-2 relative flex h-[34rem] overflow-hidden md:h-[48rem] md:overflow-visible md:pt-0">
            {data.icon && (
              <Icon
                type={data.icon}
                className={clsx(
                  isOne && 'md:top-[31rem] md:left-[52.4rem] md:w-[39rem]',
                  isTwo && 'md:top-[40rem] md:left-[64rem] md:w-[18rem]',
                  isThree && 'md:top-[44rem] md:left-[67rem] md:w-[14rem]',
                  'fill-sand absolute left-[17rem] top-[24rem] z-10 w-[8rem] md:absolute'
                )}
              />
            )}
            <Image
              src={data.images[0]}
              className={clsx(
                isOne && 'md:left-[40rem] md:top-[51rem] md:h-[33rem] md:w-[22rem]',
                isTwo &&
                  'md:left-[47rem] md:top-[7rem] md:h-[36rem] md:w-[26rem] md:rotate-[8.7deg]',
                isThree &&
                  'md:left-[76rem] md:top-[14rem] md:h-[33rem] md:w-[24rem] md:rotate-[3deg]',
                'absolute left-[-1rem] h-[28rem] w-[23rem] rotate-[-8deg] object-cover'
              )}
            />
            <Image
              src={data.images[1]}
              className={clsx(
                isOne &&
                  'md:top-[17rem] md:left-[78rem] md:h-[27rem] md:w-[36rem] md:rotate-[-3.54deg]',
                isTwo &&
                  'md:left-[73rem] md:top-[56rem] md:h-[32rem] md:w-[46rem] md:rotate-[10deg]',
                isThree &&
                  'md:left-[31rem] md:top-[52rem] md:h-[33rem] md:w-[38rem] md:rotate-[-3.3deg]',
                'absolute right-[-4rem] top-[6rem] h-[25rem] w-[20rem] rotate-[9deg]  object-cover'
              )}
            />
          </div>
        )}
      </div>
    </section>
  )
}
