import {useScroll, useSpring, useTransform, m} from 'framer-motion'
import {useRef} from 'react'
import {HeaderColorWrapper} from '../shared/header-color-wrapper'

import Image from '../shared/image'
import RichText from '../shared/rich-text'

import {colors} from '~/utils/colors'
import {eas} from '~/utils/easings'

export default function HeaderImage({data}: any) {
  const sectionRef = useRef<HTMLElement>(null)
  const imageRef = useRef<HTMLElement>(null)

  const {scrollYProgress} = useScroll({
    target: sectionRef,
    offset: ['start end', 'end start'],
  })

  const springConfig = {
    stiffness: 100,
    damping: 20,
  }

  const rotate = useSpring(useTransform(scrollYProgress, [0, 1], [0, -1]), springConfig)
  const distance = 100
  const y = useSpring(useTransform(scrollYProgress, [0, 1], [distance, -distance]), springConfig)

  return (
    <HeaderColorWrapper headerColor={colors.sand}>
      <div
        ref={sectionRef}
        style={{
          backgroundColor: data.backgroundColor,
          color: data.textColor,
        }}
      >
        <div className="wrapper py-c-8 md:py-c-4 md:pb-c-4 relative flex h-full flex-col items-center justify-center">
          <div>
            {data.topTitle && (
              <div className="tracking-pn-3 text-sand relative z-20 mb-[-2rem] text-center text-[5rem] leading-[80%] md:mb-[-8rem]  md:text-[15rem]">
                <RichText data={data.topTitle} />
              </div>
            )}

            <div className="md:w-c-9 mx-auto w-[20rem] rotate-[-1.9deg]">
              <m.div
                animate={{y: '0%'}}
                initial={{y: '100%'}}
                transition={{duration: 1.6, ease: eas.outExpo}}
              >
                <m.div style={{rotate, y: y}}>
                  {data.image && <Image src={data.image} className="w-full" ref={imageRef} />}
                </m.div>
              </m.div>
            </div>

            {data.bottomTitle && (
              <div className="tracking-pn-3 text-sand relative z-20 mt-[-1.9rem] block text-center text-[5rem] leading-[80%] md:mt-[-6rem] md:text-[15rem]">
                <RichText data={data.bottomTitle} />
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderColorWrapper>
  )
}
