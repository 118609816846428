import clsx from 'clsx'
import {useState} from 'react'
import {m} from 'framer-motion'

import {eas} from '~/utils/easings'

import Icon from '../shared/icon'
import Image from '../shared/image'

const springConfigImage = {
  duration: 0.8,
  ease: eas.outExpo,
}
const springConfigSvg = {
  type: 'spring',
  bounce: 0.4,
  duration: 0.8,
}
const viewPortSettings = {once: true}

export default function Socials({data}: any) {
  return (
    <section className="bg-skyblue md:py-c-3 relative w-screen overflow-hidden">
      <div className="md:relative">
        <div className="wrapper pt-c-3 pb-c-4 md:pt-c-1 md:flex md:flex-col-reverse md:pb-0">
          {data.banner && <Banner banner={data.banner} className="md:hidden" />}

          <div className="pt-c-0 pb-c-3 md:pt-c-0.25 md:pb-0">
            {data.text && (
              <div className="px-c-3  text-sand text-center text-base md:mx-auto md:w-[26rem] md:px-0">
                <m.div
                  transition={{...springConfigImage, delay: 0.4}}
                  initial={{opacity: 0}}
                  whileInView={{opacity: 1}}
                  viewport={viewPortSettings}
                >
                  {data.text}
                </m.div>
              </div>
            )}
          </div>
          <div className="relative z-20">
            <m.div
              viewport={viewPortSettings}
              transition={springConfigSvg}
              initial={{rotate: 80, scale: 0, y: '40%'}}
              whileInView={{rotate: 0, scale: 1, y: 0}}
              className="md:left-c-4.5 absolute top-[-3rem] left-[1rem] z-0 w-[13rem] rotate-[8deg] md:top-[-4rem] md:w-[20rem]"
            >
              <Icon type="flower" className="fill-blue " />
            </m.div>

            {data.images && (
              <div className="pt-c-2 relative flex h-[37rem] overflow-hidden md:h-[48rem] md:overflow-visible md:pt-0">
                <m.div
                  viewport={viewPortSettings}
                  transition={springConfigImage}
                  className="md:left-c-6.5 absolute left-[-1rem] h-[28rem] w-[23rem] rotate-[-8deg] object-cover md:top-[2rem] md:h-[43rem] md:w-[34rem] "
                  initial={{rotate: 20, y: '40%', x: '50%'}}
                  whileInView={{rotate: -8, y: 0, x: 0}}
                >
                  <Image src={data.images[0]} className="" />
                </m.div>
                <m.div
                  viewport={viewPortSettings}
                  transition={springConfigImage}
                  className="md:right-c-7 absolute right-[-4rem] top-[6rem] h-[25rem] w-[20rem] rotate-[9deg]  object-cover md:h-[38rem] md:w-[30rem]"
                  initial={{rotate: 20, y: '40%', x: '-50%'}}
                  whileInView={{rotate: 9, y: 0, x: 0}}
                >
                  <Image src={data.images[1]} />
                </m.div>
              </div>
            )}
            <m.div
              transition={springConfigSvg}
              initial={{rotate: -130, y: 0, scale: 0}}
              whileInView={{rotate: 0, y: 0, scale: 1}}
              viewport={viewPortSettings}
              className="md:left-c-11 absolute  left-[16rem] top-[24rem] z-20 block w-[8.7rem] rotate-[4.2deg] md:top-[35rem] md:w-[13rem]"
            >
              <Icon type="octa" className="fill-softorange" />
            </m.div>
          </div>
        </div>

        {data.banner && <Banner banner={data.banner} className="hidden md:block" />}
      </div>
    </section>
  )
}

function Banner({banner, className}) {
  const [anmimateBanner, setAnimateBanner] = useState(false)
  return (
    <div
      className={clsx(
        'relative z-0 overflow-hidden py-[1rem] md:absolute md:top-[18rem]',
        className
      )}
    >
      <m.div
        transition={{...springConfigImage, delay: 0.2}}
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        viewport={viewPortSettings}
        onAnimationStart={() => setAnimateBanner(true)}
      >
        <div className="text-sand leading-1 flex items-center  whitespace-nowrap text-[5rem] md:text-[15rem]">
          <div className={clsx('tracking-pn-3 flex', anmimateBanner && 'animate-social')}>
            <span>{banner} </span>
            <span>{banner} </span>
          </div>
          <div
            className={clsx(
              anmimateBanner && 'animate-social-2 opacity-1',
              !anmimateBanner && 'opacity-0',
              'tracking-pn-3 absolute ml-[.5rem] flex'
            )}
          >
            <span>{banner} </span> <span>{banner} </span>
          </div>
        </div>
      </m.div>
    </div>
  )
}
