import {HeaderColorWrapper} from '../shared/header-color-wrapper'
import Image from '../shared/image'
import RichText from '../shared/rich-text'

import {colors} from '~/utils/colors'

export default function Founder({data}: any) {
  return (
    <HeaderColorWrapper headerColor={colors.softorange} className="bg-sand">
      <div className="wrapper pt-c-4 pb-c-8 md:pt-c-3 md:pb-c-4 relative flex  flex-col items-center md:flex-row md:justify-end">
        <div className="mx-c-4 md:ml-c-3 md:w-c-6 md:absolute md:left-0">
          {data.title && (
            <h2 className="tracking-pn-3 pb-c-1 md:pb-c-0.5 text-[3.5rem] leading-[85%] md:text-[5rem]">
              {data.title}
            </h2>
          )}
          {data.text && (
            <RichText
              data={data.text}
              className="pb-c-1  text-base"
              textClassName="pb-c-1 md:pb-c-0.25"
            />
          )}
        </div>

        <div className="mt-c-2 md:w-c-12 relative flex items-center justify-center md:mt-0">
          <div className="w-c-22 md:w-c-10  md:mr-c-2 relative overflow-hidden">
            {data.image && <Image src={data.image} className="z-10 " />}
          </div>
        </div>
      </div>
    </HeaderColorWrapper>
  )
}
