import Button from '../shared/button'

import RichText from '../shared/rich-text'

export default function Cta({data}: any) {
  return (
    <section className="bg-sand">
      <div className="relative flex flex-col items-center justify-center py-[20rem] md:py-[22rem]">
        {data.text && (
          <div className="tracking-pn-3 text-center text-[4rem] leading-[80%] md:text-[7rem]">
            <RichText data={data.text} />
          </div>
        )}
        {data.button && <Button data={data.button} className="md:mt-c-0.5 mt-[3.2rem]" />}
      </div>
    </section>
  )
}
