import FeaturedProduct from './featured-product'
import Founder from './founder'
import HeaderImage from './header-image'
import Hero from './hero'
import Socials from './socials'
import TextImage from './text-image'
import Highlight from './highlight'
import Cta from './cta'
import Faq from './faq'
import Text from './text'

//TODO DYNAMIC IMPORT FOR THESE SECTIONS FOR PAGE OPTIMISATION
// next example const SectionBannerCta = dynamic(() => import('./section-banner-cta/section-banner-cta.jsx'))

function Section({data}: any) {
  const type = data!._type as string

  const commonProps = {
    data,
  }

  const sectionMapper = {
    hero: <Hero {...commonProps} />,
    featuredProduct: <FeaturedProduct {...commonProps} />,
    textImage: <TextImage {...commonProps} />,
    socials: <Socials {...commonProps} />,
    headerImage: <HeaderImage {...commonProps} />,
    founder: <Founder {...commonProps} />,
    highlight: <Highlight {...commonProps} />,
    cta: <Cta {...commonProps} />,
    faq: <Faq {...commonProps} />,
    textSection: <Text {...commonProps} />,
  }

  //@ts-ignore
  return sectionMapper[type] ?? <div>No sections found for {type}</div>
}

export default function Sections({data}: {data: any}) {
  return (
    data &&
    data.map((section: any, i: number) => {
      const key = section._type ? section._type + i : i

      return <Section data={section} key={key} />
    })
  )
}
