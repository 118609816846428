import clsx from 'clsx'
import {m, useAnimationControls, useMotionValue, useSpring} from 'framer-motion'
import {useRef} from 'react'

import {colors} from '~/utils/colors'

import Button from '../shared/button'
import Image from '../shared/image'
import RichText from '../shared/rich-text'

import {eas} from '~/utils/easings'

import {HeaderColorWrapper} from '../shared/header-color-wrapper'

export default function Hero({data}: any) {
  const textRef = useRef<HTMLDivElement>(null)

  const controls = useAnimationControls()

  let mouse = {}

  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    const {clientX, clientY} = event
    mouse = {x: clientX, y: clientY, distance: 0}
  }

  const handleMouseMove = (event) => {
    const calculateDistance = (x1, y1, x2, y2) =>
      Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2))

    const {clientX, clientY} = event
    const {distance: distancessss} = mouse

    mouse = {
      x: clientX,
      y: clientY,
      // distance: distancessss + calculateDistance(x, y, clientX, clientY),
    }

    const value = {
      x: (mouse.x - window.innerWidth / 2) / 1.5,
      y: (mouse.y - window.innerHeight / 2) / 5,
      rotate: (mouse.x - window.innerWidth / 2) / 100,
    }

    controls.start({
      x: (mouse.x - window.innerWidth / 2) / 1.5,
      y: (mouse.y - window.innerHeight / 2) / 5,
      rotate: (mouse.x - window.innerWidth / 2) / 100,
    })
  }

  const handleMouseLeave = () => {
    // mouse = null
    // api.start({
    //   to: {
    //     x: 0,
    //     y: 0,
    //     rotate: 0,
    //   },
    // })
  }

  // if (newMouse.distance > 500) {
  //   let newArray = [...imagesArray]
  //   newArray.push(newArray.shift())
  //   setNewMouse((oldMouse) => ({...oldMouse, distance: 0}))
  //   setImageArray(newArray)
  //   // let newColArray = [...colorArray]
  //   // newColArray.push(newColArray.shift())
  //   // setColorArray(newColArray)
  // }
  return (
    <HeaderColorWrapper
      className="bg-ocher"
      headerColor={colors.sand}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="wrapper relative flex h-screen flex-col items-center justify-center overflow-hidden">
        <m.div
          className="w-c-20 my-c-6 md:w-c-6 md:my-c-1.5 mx-auto rotate-[-1.9deg] "
          // initial={{y: '100%', rotate: '-1.9deg'}}
          // animate={{y: '00%'}}
          // transition={{duration: 4, ease: eas.wakey}}
        >
          {data.images.map((image: any, i: number) => {
            return (
              <m.div
                style={{
                  zIndex: 99 - i,
                }}
                animate={controls}
                transition={{delay: i * 0.0005}}
                key={i}
                className={clsx(
                  'relative h-[45rem] w-full object-cover md:h-[50rem] md:w-fit',
                  i !== 0 && '!absolute inset-0 '
                  // i > 3 && '!hidden'
                )}
              >
                <Image src={image} className=" h-full object-cover" />
              </m.div>
            )
          })}
        </m.div>

        {/* <div className="absolute inset-0 z-10 flex  h-full w-full flex-col items-center justify-center opacity-0 md:h-screen">
          {data.text && (
            <div
              className="tracking-pn-3 text-sand cursor-default text-center text-[5rem] leading-[80%] md:text-[13rem]"
              ref={textRef}
            >
              <RichText data={data.text} />
            </div>
          )}

          {data.button && <Button data={data.button} className="mt-c-1 md:mt-c-0.5" />}
        </div> */}
      </div>
    </HeaderColorWrapper>
  )
}
