import {useState} from 'react'
import Accordion from '../shared/accordion'
import {HeaderColorWrapper} from '../shared/header-color-wrapper'
import RichText from '../shared/rich-text'
import {colors} from '~/utils/colors'

export default function Faq({data}: any) {
  const [accordionOpen, setAccordionOpen] = useState(null)
  return (
    <HeaderColorWrapper
      headerColor={colors.black}
      style={{
        backgroundColor: data.backgroundColor,
        color: data.textColor,
      }}
    >
      <div className="wrapper pb-c-4 md:pb-c-2">
        <div>
          {data.bigTitle && (
            <h1 className="tracking-pn-3 pt-c-9  pb-c-4 md:pt-c-3 md:pb-c-2 relative z-20 text-center text-[5.5rem] leading-[85%] md:text-[20rem] md:leading-[80%]">
              <RichText data={data.bigTitle} />
            </h1>
          )}
        </div>
        <div className="px-c-2 md:grid md:grid-cols-2">
          <div>
            {data.title && (
              <h2 className="pb-c-1.5 tracking-pn-3 text-[3.5rem] leading-[85%] md:text-[4.8rem]">
                {data.title}
              </h2>
            )}
          </div>
          <ul>
            {data.items &&
              data.items.map(({text, title, open: startOpen}, index: number) => (
                <Accordion
                  text={text}
                  title={title}
                  key={title}
                  index={index}
                  open={accordionOpen === null ? startOpen : accordionOpen === index}
                  onButtonClick={() =>
                    setAccordionOpen((state) => (state === index ? null : index))
                  }
                />
              ))}
          </ul>
        </div>
      </div>
    </HeaderColorWrapper>
  )
}
