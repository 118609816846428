import {json, LoaderArgs, type MetaFunction} from '@shopify/remix-oxygen'
import type {LoaderFunction} from '@shopify/remix-oxygen'
import {useLoaderData} from '@remix-run/react'
import {getLocaleFromRequest} from '~/utils/locale'
import Sections from '~/components/sections'
import {NotFound} from '~/components/shared/404'
import {countries} from '~/models/countries'

import {getSeoMetaData} from '~/utils/seo'
import cms from '~/cms.server'
import {AnalyticsPageType} from '@shopify/hydrogen'

type LoaderData = {
  page: Awaited<ReturnType<typeof getPage>>
  analytics: any
}

export const loader: LoaderFunction = async ({request, params, context}: LoaderArgs) => {
  const {language} = getLocaleFromRequest(request)

  const {handle, lang} = params

  if (lang && !countries[`/${lang}`]) {
    throw new Response('No correct lang', {
      status: 404,
    })
  }

  let page = await cms.getPage({locale: language, slug: handle!})

  if (!page) {
    throw new Response('Not Found', {
      status: 404,
    })
  }

  return json<LoaderData>({
    page,
    analytics: {
      pageType: AnalyticsPageType.home,
    },
  })
}

export const meta: MetaFunction = ({data, parentsData, location}) => {
  return getSeoMetaData({
    page: data.page?.seo,
    fallback: parentsData?.root?.settings?.seo,
    location: location,
  })
}

export default function Page() {
  const data = useLoaderData()

  const sections = data.page?.sections

  return <Sections data={sections} />
}

export function CatchBoundary() {
  return <NotFound />
}
