import {m} from 'framer-motion'

import {colors} from '~/utils/colors'

import Button from '../shared/button'
import Icon from '../shared/icon'
import Image from '../shared/image'
import RichText from '../shared/rich-text'

import {eas} from '~/utils/easings'
import {HeaderColorWrapper} from '../shared/header-color-wrapper'

export default function TextImage({data}: any) {
  const springConfigImage = {
    duration: 0.8,
    ease: eas.outExpo,
  }
  const springConfigSvg = {
    type: 'spring',
    bounce: 0.4,
    duration: 0.8,
  }

  const viewPortSettings = {once: true}

  return (
    <HeaderColorWrapper headerColor={colors.sand} className="bg-yellow overflow-hidden">
      <div className="wrapper pt-c-4 pb-c-8 md:pt-c-3 md:pb-c-4 relative flex  flex-col items-center md:flex-row md:justify-end">
        <div className="mx-c-4 md:ml-c-3 md:w-c-6 md:absolute md:left-0">
          {data.title && (
            <h2 className="tracking-pn-3 pb-c-1  text-[3.5rem] leading-[85%] md:pb-[2rem]  md:text-[5rem]">
              {data.title}
            </h2>
          )}
          {data.text && <RichText data={data.text} className="pb-c-1  text-base md:pb-[2rem]" />}
          {data.button && <Button data={data.button} className="" />}
        </div>

        <div className="mt-c-6 md:w-c-14 relative flex items-center justify-center md:mt-0">
          <m.div
            viewport={viewPortSettings}
            transition={springConfigSvg}
            initial={{rotate: 80, scale: 0, y: '40%'}}
            whileInView={{rotate: 0, scale: 1, y: 0}}
            className="w-c-7 absolute top-[-5rem] left-[-1rem] z-20 md:top-[2rem] md:left-[-7rem] md:w-[23rem]"
          >
            <Icon type="sun" className="fill-ocher " />
          </m.div>

          {data.image && (
            <m.div
              viewport={viewPortSettings}
              transition={springConfigImage}
              className="w-c-21 md:w-c-11  md:mr-c-2 z-10 rotate-[3.29deg] "
              initial={{rotate: 20, y: '40%'}}
              whileInView={{rotate: 3, y: 0}}
            >
              <Image src={data.image} />
            </m.div>
          )}
          <m.div
            transition={springConfigSvg}
            initial={{rotate: -130, y: 0, scale: 0}}
            whileInView={{rotate: 0, y: 0, scale: 1}}
            viewport={viewPortSettings}
            className="w-c-7 md:right-c-1.5 absolute bottom-[-5rem] right-[-1rem] z-20  md:bottom-[-8rem] md:w-[23rem]"
          >
            <Icon type="flower" className=" fill-skyblue " />
          </m.div>
        </div>
      </div>
    </HeaderColorWrapper>
  )
}
